import { takeLatest, call, put, all } from 'redux-saga/effects';
import jwt_decode from 'jwt-decode';
import Cookies from 'universal-cookie';
import history from '../../../services/history';
import logoEmpresa from "../../../assets/img/brand/sygnet.png";
import api from '../../../services/endpoints';

import { signInSuccess, signOutSuccess, signFailure } from './actions';
const cookies = new Cookies();

export function* signIn({ payload }) {
  console.log('signIn')
  try {
    const { email, password } = payload;

    const response = yield call(api.apiClient.post, 'authenticate/', {
      username: email,
      password: password
    });

    if (response.status) {
      const { token } = response.data;

      const decoded = jwt_decode(token.slice(4));

      const user = {
        id: decoded.userId,
        clientId: decoded.clientId,
        endpoints: decoded.endpoints,
        contexts: decoded.contexts,
        isAdmin: decoded.isAdmin,
        sessionId: decoded.sessionId,
        avatar: "assets/img/avatars/0.png",
        nome: "Novida",
        email: decoded.username,
        empresa: {
          nome: "Novida",
          logo: logoEmpresa
        },
        telefone: "(11) 99999-9999",
        cargo: "Coordenador"
      };

      cookies.set('session', token.slice(4), { path: '/' });
      cookies.set('session', token.slice(4), { path: "/", domain: ".novida.co" })

      yield put(signInSuccess(token.slice(4), user));
      history.push("/home");
    } else {
      console.log('erro no status')
      yield put(signFailure());
    }

  } catch (err) {
    console.log('erro no request')
    yield put(signFailure());
    localStorage.removeItem('persist:dashboard-novida');
    localStorage.removeItem('relatorioCsv');
    cookies.remove('session', { path: '/' });
    cookies.remove('session', { path: "/", domain: ".novida.co" })
    cookies.remove('operacoesSelect', { path: '/' });
    cookies.remove('io', { path: '/' });
    cookies.remove('recursoSelect', { path: '/' });
    cookies.set('error', 'Usuário ou senha inválida', { path: '/' });
    window.location.reload();
  }
}

export function* signOut() {
  yield put(signOutSuccess());
  localStorage.removeItem('persist:dashboard-novida');
  localStorage.removeItem('relatorioCsv');
  cookies.remove('session', { path: '/' });
  cookies.remove('session', { path: "/", domain: ".novida.co" })
  cookies.remove('operacoesSelect', { path: '/' });
  cookies.remove('io', { path: '/' });
  cookies.remove('recursoSelect', { path: '/' });
  history.push("/login");
  window.location.reload();
}

export default all([
  takeLatest('@auth/SIGN_IN_REQUEST', signIn),
  takeLatest('@auth/SIGN_OUT', signOut),
]);
