import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import jwt_decode from 'jwt-decode';

import { store } from '../store';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

export default function RouteWrapper({
  component: Component,
  isPrivate,
  ...rest
}) {

  const { signed } = store.getState().auth;
  // console.log('validando versao do dashboard')
  if (signed) {
    const decoded = jwt_decode(store.getState().auth.token);
    const dashversao = decoded.contexts.find(obj => {
      if (obj.dash === '2.0.0') {
        return true
      }
      return false;
    })

    if (!dashversao) {
      localStorage.removeItem('persist:dashboard-novida');
      localStorage.removeItem('relatorioCsv');
      cookies.remove('session', { path: '/' });
      cookies.remove('session', { path: "/", domain: ".novida.co" })
      cookies.remove('operacoesSelect', { path: '/' });
      cookies.remove('io', { path: '/' });
      cookies.remove('recursoSelect', { path: '/' });
      cookies.set('error', 'Sua sessão expirou, Logue novamente.', { path: '/' });
      window.location.reload();
      // window.location = window.location.href+'?eraseCache=true';
    }
  }


  return (
    <Route
      {...rest}
      render={props => {

        if (!signed && isPrivate) {
          return <Redirect to="/login" />
        }

        if (signed && !isPrivate) {
          return <Redirect to="/home" />
        }

        return <Component {...props} />

      }
      }
    />
  )
}

RouteWrapper.propTypes = {
  isPrivate: PropTypes.bool,
  component: PropTypes
    .oneOfType([PropTypes.element, PropTypes.func, PropTypes.object])
    .isRequired
};

RouteWrapper.defaultProps = {
  isPrivate: false,
};
